import React, { useEffect, useState } from "react";
import { Tabs, Placeholder, ButtonGroup, Button, Panel } from 'rsuite';
import { GetPartnerApplicationsFetch } from "../../connector/connectorV2";
import { app_type_to_text } from "./const";
import { useNavigate } from "react-router";
export default function PartnerApplications(props){
    const [activeKey, setActiveKey] = useState('Активные');
    const [applications, set_applications] = useState([])
    useEffect(() => {
        GetApplicationsAction()
    }, [activeKey])

    const GetApplicationsAction = () => {
        let main_type = "active"
        if (activeKey == "Закрытые") {
            main_type = "not_active"
        }
        GetPartnerApplicationsFetch(main_type).then(res => {
            set_applications(res.data)
        })
    }
    const navigate = useNavigate()
    return (
        <div className="partner_page_container">
            <div className="simple_text">Заявки</div>
            <div className="partner_filter_wrapper">
                {/* <ButtonGroup>
                    {['Активные', 'Закрытые'].map(key => (
                    <Button key={key} active={key === activeKey} onClick={() => setActiveKey(key)}>
                        {key}
                    </Button>
                    ))}
                </ButtonGroup> */}
                <Button className="main_btn" onClick={(e) => {navigate("/application")}}>
                    Оставить новую заявку
                </Button>
            </div>
            
            <div className="partner_table scroll_stylle">

                <div className="partner_app_tr partner_app_tr_header">
                    <div className="partner_app_td">№ заявки</div>
                    <div className="partner_app_td">Тип</div>
                    <div className="partner_app_td">Дата</div>
                    <div className="partner_app_td">Статус</div>
                    <div className="partner_app_td">Трек номер</div>
                    <div className="partner_app_td"></div>
                </div>
                {applications?.map((val,i) => 
                    <div className="partner_app_tr" key={`part_app_${i}`}>
                        <div className="partner_app_td">{val?.id}</div>
                        <div className="partner_app_td">{val?.type}</div>
                        <div className="partner_app_td">{val.create_datetime ? new Date(val.create_datetime).toLocaleString() : "-"}</div>
                        <div className="partner_app_td">{app_type_to_text[val.status] || "Принята"}</div>
                        <div className="partner_app_td">{val?.application_hash}</div>
                        <div className="partner_app_td"><a target="_blank" href={`/application-tracking?track=${val.application_hash}&id=${val.id}`}>отслеживать</a></div>
                    </div>
                )}
            </div>
        </div>
    )
}